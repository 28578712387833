<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="offers"
              backText="MY OFFERS"
              :title="modelAbout.name"
              sub-title="KNOW MORE ABOUT THIS MODEL"
              :login="login"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <ModelDetail :galleries="galleries" :model-about="modelAbout" />
    <footer>
      <div class="wa__home--footer">
        <site-footer text="All Right Reserved"></site-footer>
      </div>
    </footer>
  </div>
</template>

<script>
import ModelRepository from "@/abstraction/repository/modelRepository";
import ModelDetail from "./ModelDetail.vue";
export default {
  components: {
    ModelDetail,
  },
  data() {
    return {
      modelAbout: {
        gender: {},
        model: {
          eye_color: {},
          hair_color: {},
          account_classification: {},
        },
        model_types: [],
        languagesWithLevel: [],
      },
      galleries :[],
      login: true,
    };
  },
  methods: {
    async loadModelAbout() {
      let repository = new ModelRepository();
      this.modelAbout = await repository.show(this.$route.params.id);
      this.galleries = this.modelAbout.model.galleries ? this.modelAbout.model.galleries.map((x) => {
        return {
          thumb: x.thumbnail,
          src: x.image,
        };
      }) : [];
    },
  },
  created() {
    this.loadModelAbout();
  },
};
</script>

<style></style>
